import styled from '@emotion/styled';
import { css, Global } from '@emotion/react';
import { trans } from '@spotahome/soyuz/client';

import Button from '@spotahome/landlord-panel-ui-library/src/components/Button';
import colors from '@spotahome/landlord-panel-ui-library/src/shared/colors';
import { space } from '@spotahome/landlord-panel-ui-library/src/shared/spacing';
import {
  mediaQueries,
  breakpoints
} from '@spotahome/landlord-panel-ui-library/src/shared/breakpoints';
import { mediaQueries as ratiosMQ } from '@spotahome/landlord-panel-ui-library/src/shared/screen-ratios';
import fonts from '@spotahome/landlord-panel-ui-library/src/shared/fonts';
import ThemeProvider from '@spotahome/landlord-panel-ui-library/src/components/ThemeProvider';

import { globalStyles } from '@spotahome/landlord-panel-ui-library/src/shared/styles';

import ResponsiveLogo from '@spotahome/auth-common/src/components/ResponsiveLogo';

const Container = styled.div`
  display: flex;
  flex-grow: 1;
  min-height: 100vh;
`;

const BackgroundDiv = styled.div`
  background: url('/images/not-found/mobile/404-bg.jpg') no-repeat center center;
  background-size: cover;
  position: absolute;
  height: 100%;
  width: 100%;

  ${ratiosMQ.x2(css`
    background-image: url('/images/not-found/mobile/404-bg@2x.jpg');
  `)};

  ${ratiosMQ.x3(css`
    background-image: url('/images/not-found/mobile/404-bg@3x.jpg');
  `)};

  ${mediaQueries.md(css`
    background-image: url('/images/not-found/tablet/404-bg.jpg');

    ${ratiosMQ.x2(css`
      background-image: url('/images/not-found/tablet/404-bg@2x.jpg');
    `)};

    ${ratiosMQ.x3(css`
      background-image: url('/images/not-found/tablet/404-bg@3x.jpg');
    `)};
  `)};

  ${mediaQueries.lg(css`
    background-image: url('/images/not-found/desktop/404-bg.jpg');

    ${ratiosMQ.x2(css`
      background-image: url('/images/not-found/desktop/404-bg@2x.jpg');
    `)};

    ${ratiosMQ.x3(css`
      background-image: url('/images/not-found/desktop/404-bg@3x.jpg');
    `)};
  `)};

  ${mediaQueries.xl(css`
    width: 66.67%;
    height: auto;
    position: inherit;
  `)};
`;

const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  max-width: 288px;
  color: ${colors.white};
  padding: ${space.x5} 0;
  font-size: ${fonts.sizes.xl};
  margin: 0 auto;
  z-index: 1;

  p {
    line-height: 1.4;
    margin: ${space.x15};
    font-family: ${fonts.GothamLight};
  }

  ${mediaQueries.md(css`
    button {
      margin-top: ${space.x1};
    }
  `)};

  ${mediaQueries.between(
    breakpoints.md,
    breakpoints.xl
  )(css`
    margin-top: ${space.x8};
    max-width: 362px;

    button {
      width: 288px;
      height: 48px;
    }
  `)};

  ${mediaQueries.xl(css`
    justify-content: center;
    color: ${colors.greyDark};
  `)};
`;

const ErrorCode = styled.h2`
  font-size: ${fonts.sizes.xxxxl};
  font-family: ${fonts.GothamMedium};
  margin: 0;
`;

const ErrorDescription = styled.span`
  font-size: ${fonts.sizes.m};

  ${mediaQueries.md(css`
    margin-bottom: ${space.x3};
  `)};
`;

const NotFound = () => (
  <ThemeProvider>
    <Global styles={globalStyles} />
    <Container>
      <BackgroundDiv />
      <InfoContainer>
        <ResponsiveLogo />
        <ErrorCode>404</ErrorCode>
        <ErrorDescription>
          {trans('sahc.no-match.error.title')}
        </ErrorDescription>
        <p>{trans('sahc.no-match.error.description')}</p>
        <p>{trans('sahc.no-match.error.solution')}</p>
        <Button fullWidthOnMobile as="a" href="/properties">
          {trans('sahc.no-match.label.redirect-to-properties')}
        </Button>
      </InfoContainer>
    </Container>
  </ThemeProvider>
);

export default NotFound;
