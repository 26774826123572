import { Component } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';

import { Logo } from '@spotahome/ui-library/base';

import debounceThrottle from '@spotahome/landlord-panel-ui-library/src/utils/debounce-throttle';
import { space } from '@spotahome/landlord-panel-ui-library/src/shared/spacing';
import {
  mediaQueries,
  breakpoints
} from '@spotahome/landlord-panel-ui-library/src/shared/breakpoints';

const Container = styled.div`
  margin-bottom: ${space.x3};

  ${mediaQueries.md(css`
    margin-bottom: ${space.x5};
  `)};
`;

function calculateColor(screenWidth) {
  return screenWidth >= breakpoints.xl ? 'dark' : 'light';
}

function calculateSize(screenWidth) {
  return screenWidth >= breakpoints.md ? 'large' : 'normal';
}

function calculateState(prevState, screenWidth) {
  return {
    ...prevState,
    color: calculateColor(screenWidth),
    size: calculateSize(screenWidth)
  };
}

class ResponsiveLogo extends Component {
  state = {
    ...calculateState({}, window.innerWidth)
  };

  updateState = debounceThrottle(() => {
    this.setState(() => ({
      ...calculateState({}, window.innerWidth)
    }));
  });

  componentDidMount() {
    window.addEventListener('resize', this.updateState);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateState);
  }

  render() {
    const { color, size } = this.state;
    return (
      <Container>
        <Logo theme={color} size={size} />
      </Container>
    );
  }
}

export default ResponsiveLogo;
